import React from 'react'
import { css } from 'emotion'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ContactIcon from '../components/small/contact-icon'

import { email, siteName } from '../data'

const breakPoint = 800

const wrap = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakPoint}px) {
    flex-direction: row;
  }
`

const col = css`
  @media (max-width: ${breakPoint - 1}px) {
    margin-bottom: 20px;
  }

  @media (min-width: ${breakPoint}px) {
    width: calc(50% - 10px);
  }
`

const emailWrap = css`
  display: flex;
  align-items: center;

  a {
    margin-right: 10px;
  }
`

const ContactPage = () => (
  <Layout>
    <Helmet title={`Contact - ${siteName}`} />
    <h1>Contact</h1>
    <div className={wrap}>
      <div className={col}>
        <p>
          Please contact me with any queries or to request a free initial
          consultation, evening and weekend appointments may be available.
          <br />
          <br />
          <div className={emailWrap}>
            <ContactIcon type="email" />
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </p>
      </div>
      <div className={col}>
        <p>You can also contact me via the following:</p>
        <ul>
          <li>
            <ContactIcon type="skype" text="true" />
          </li>
          <li>
            <ContactIcon type="linkedin" text="true" />
          </li>
          <li>
            <ContactIcon type="phone" text="true" />
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default ContactPage
